<template>
  <b-icon icon="github" :title="text || 'Github'"></b-icon>
</template>

<script>
import BaseCell from '../BaseIconWrapper'

export default {
  extends: BaseCell
}
</script>
