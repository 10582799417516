<template>
  <b-icon icon="sort-down-alt" :title="text"></b-icon>
</template>

<script>
import BaseCell from '../BaseIconWrapper'

export default {
  extends: BaseCell
}
</script>
