<template>
  <b-icon icon="calendar-week" :title="text || 'Date'"></b-icon>
</template>

<script>
import BaseCell from '../BaseIconWrapper'

export default {
  extends: BaseCell
}
</script>
