<template>
  <b-icon icon="arrow-right-square" :title="text"></b-icon>
</template>

<script>
import BaseCell from '../BaseIconWrapper'

export default {
  extends: BaseCell
}
</script>
