<template>
  <b-icon icon="envelope-fill" :title="text"></b-icon>
</template>

<script>
import BaseCell from '../BaseIconWrapper'

export default {
  extends: BaseCell
}
</script>
