<template>
  <b-icon icon="arrow-right-circle" :title="text || 'Go to page'"></b-icon>
</template>

<script>
import BaseCell from '../BaseIconWrapper'

export default {
  extends: BaseCell
}
</script>
