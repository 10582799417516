export const ICON_NAMES = {
  NEXT_BUTTON: 'nextButton',
  PREVIOUS_BUTTON: 'previousButton',
  ARROW_LINK: 'arrowLink',
  ARROW_LINK_BACK: 'arrowLinkBack',
  CALENDAR: 'calendar',
  SOURCE_LINK: 'sourceLink',
  SHOW_MORE: 'showMore',
  TAG: 'tag',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  GITHUB: 'github',
  LINKEDIN: 'linkedin',
  CLEAR: 'clear',
  MENU: 'menu',
  CLOSE_MENU: 'closeMenu',
  WATCHLIST: 'watchlist',
  WATCHLIST_SELECTED_ITEM: 'watchlistSelectedItem',
  WATCHLIST_UNSELECTED_ITEM: 'watchlistUnselectedItem',
  INFO: 'info',
  ARROW_RIGHT: 'arrowRight',
  ARROW_UP: 'arrowUp',
  ARROW_RIGHT_SQUARE: 'arrowRightSquare',
  UPDATE: 'update',
  ADD: 'add',
  DELETE: 'delete',
  EDIT_COLUMNS: 'editColumns',
  EXTERNAL_LINK: 'externalLink',
  EXPAND: 'expand',
  COLLAPSE: 'collapse',
  SORT: 'sort',
  SORT_ASC: 'sortAsc',
  SORT_DESC: 'sortDesc',
  CHECK_MARK: 'checkMark',
  MAIL: 'mail',
  TELEGRAM: 'telegram'
}
