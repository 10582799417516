<template>
  <b-icon icon="arrow-left-square" :title="text || 'Go to previous page'"></b-icon>
</template>

<script>
import BaseCell from '../BaseIconWrapper'

export default {
  extends: BaseCell
}
</script>
